:root {
  --highlightColor: rgba(255, 0, 0, 0.658);
  --backgroundColor: #F8F9FA;
  --textColor: black;
  --filter: invert(60%) sepia(49%) saturate(7262%) hue-rotate(331deg) brightness(103%) contrast(97%);
  --vh: 1vh;
  --font-lg: 20px;
  --font-md: 15px;
  --font-sm: 13px;
  --font-xs: 11px;
  --font-xss: 8px;
}

/* 
#root{
  transition: 0.5s all ease-in-out;
} */

@font-face {
  font-family: "Russo_One";
  src: url("./assets/fonts/RussoOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Marker";
  src: url("./assets/fonts/PermanentMarker-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  font-size: var(--font-lg);
  touch-action: none;
  -webkit-text-size-adjust: none;
}

@media only screen and (max-width: 1150px) {
  body {
    font-size: var(--font-md);
  }
}

@media only screen and (max-width: 500px) {
  body {
    font-size: var(--font-sm);
  }
  @media only screen and (max-height: 680px) {
    body {
      font-size: var(--font-xs) !important;
    }
  }
}

@media only screen and (max-width: 350px) {
  body {
    font-size: var(--font-xs);
  }
  @media only screen and (max-height: 500px) {
    body {
      font-size: var(--font-xss) !important;
    }
  }
}

@media only screen and (max-height: 680px) {
  body {
    font-size: var(--font-sm);
  }
}

html {
  height: 100%;
  color: var(--textColor);
  overflow: hidden;
}

#root {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

#root {
  height: 100%;
}

.content {
  height: 100%;
}

.highlight {
  color: var(--highlightColor);
}

.font-12 {
  font-size: 12px;
}

.font-15 {
  font-size: 15px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-25 {
  font-size: 25px;
}

.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

.delayed {
  animation: delayed ease-in 2s both;
}

@keyframes delayed {
  0% {
    color: transparent;
  }
  100% {
    color: var(--highlightColor);
  }
}

/* -----------------------------------Navbar------------------------------- */

.navbar-part1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50%;
  justify-content: flex-start;
  padding-top: 1rem;
  position: absolute;
  z-index: 100;
  align-items: center;
  left: 4rem;
  font-size: 17px;
}

.navbar-part1-bis {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
  padding-top: 1rem;
  position: absolute;
  z-index: 100;
  left: 4rem;
  font-size: 17px;
}

#logo-web {
  position: absolute;
  width: 60px;
  height: 50px;
  z-index: 200;
  filter: var(--filter);
  left: 1rem;
}

.navbar-part1 a {
  margin-left: 2rem;
  text-decoration: none;
  color: black;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}

.navbar-part1-bis a {
  margin-left: 2rem;
  margin-bottom: 1rem;
  text-decoration: none;
  color: black;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}

.navbar-part1 a:hover, .navbar-part1-bis a:hover {
  color: var(--highlightColor);
  font: bolder;
  text-shadow: 1px 1px 2px black;
  font-size: 18px;
}

.navbar-part2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 10%;
  justify-content: space-around;
  padding-top: 1rem;
  padding-right: 1rem;
  position: absolute;
  right: 0;
  z-index: 100;
}

#language-part {
  position: relative;
  cursor: pointer;
}

.language-text {
  position: absolute;
  opacity: 0;
  transition: 0.5s all ease-in-out;
  text-align: center;
  color: black;
}

#language-and-text:hover .language-text {
  opacity: 1;
}

#select-language {
  position: absolute;
  right: 35px;
}

#color-selector {
  position: relative;
}

.dots {
  position: absolute;
}

.dot-text {
  position: absolute;
  opacity: 0;
  transition: 0.5s all ease-in-out;
  text-align: center;
  color: black;
}

#dot-and-text:hover .dot-text {
  opacity: 1;
}

.dot, .dot1, .dot2, .dot3, .dot4 {
  height: 25px;
  width: 25px;
  background-color: var(--backgroundColor);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--highlightColor);
  border: 2px solid black;
  font-weight: bolder;
  cursor: pointer;
}

.dot1 {
  background: #F8F9FA;
  color: rgba(255, 0, 0, 0.658);
  font-weight: bolder;
}

.dot2 {
  background: #012844;
  color: #dfe04e;
  font-weight: bolder;
}

.dot3 {
  background: #c4989b;
  color: #002394;
  font-weight: bolder;
}

.dot4 {
  background: #0d0d0d;
  color: #bb7151;
  font-weight: bolder;
}

.dot p {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .navbar-part2 {
    width: 20%;
  }
}

/* ------------------------------Home Page------------------------------- */

#home-page {
  height: 100%;
  position: relative;
}

#home-picture {
  background-size: cover;
  background-position: center;
  min-height: 100%;
  width: 100%;
  background-image: url("./assets/pictures/home.jpg");
  opacity: 0.4;
  background-position-y: 75%;
}

#home-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
}

#home-name {
  font-size: 50px;
  font-family: "Marker";
  color: black;
}

#home-title {
  font-size: 30px;
  position: relative;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  color: var(--highlightColor);
  text-shadow: 1px 1px 2px black;
}

#home-scroll {
  font-size: 20px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: opacityOn 3s infinite 0s normal forwards;
  cursor: pointer;
  color: black;
}

@keyframes opacityOn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.anim-typewriter {
  animation: typewriter 3s steps(40) 0s normal both;
}

@keyframes typewriter {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #home-name {
    font-size: 30px;
  }
  #home-title {
    font-size: 18px;
  }
}

/* ------------------------------ Footer ----------------------------- */

#footer-content {
  position: relative;
  z-index: 100;
  bottom: 0;
  min-height: 2.5rem;
  width: 100%;
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* ------------------------------------ About ----------------------------------- */

#about-page {
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
}

#about-page-title {
  margin: 3rem auto 0rem auto;
  padding-bottom: 2rem;
  position: relative;
  font-weight: bold;
  font-family: "Russo_one";
  font-size: 45px;
}

#about-page-title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60%;
  border-bottom: 5px solid var(--textColor);
}

#about-page-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
}

#about-page-left {
  width: 50%;
}

#about-page-right {
  width: 50%;
}

#about-page-left-text {
  width: 70%;
  text-align: justify;
  margin: auto;
}

.about-name {
  font-size: 5vh;
  font-weight: bolder;
}

#about-page-right-text {
  min-width: 300px;
  width: 70%;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  margin: auto;
}

.bold {
  font-weight: bold;
}

#about-page p {
  margin-bottom: 2rem;
}

#about-page-left-text p:nth-of-type(5) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#about-page-left-text p:nth-of-type(5) a {
  margin-left: 2rem;
  color: var(--textColor);
  text-decoration: none;
}

#about-page-left-text p:nth-of-type(5) svg {
  margin-right: 1rem;
}

.logo-github {
  font-size: 30px;
  padding: 2px;
  text-decoration: none;
  color: var(--textColor);
}

.logo-linkedin-about {
  font-size: 30px;
  padding: 2px;
  color: var(--backgroundColor);
  background-color: var(--textColor);
}

.logo-cv-about {
  font-size: 30px;
  padding: 2px;
  color: var(--textColor);
}

@media only screen and (max-width: 1000px) {
  .logo-github {
    font-size: 20px;
  }
  .logo-linkedin-about {
    font-size: 20px;
  }
  .logo-cv-about {
    font-size: 20px;
  }
}

@media only screen and (max-width: 800px) {
  #about-page-title {
    margin: 1rem auto 0rem auto;
    font-size: 30px;
    padding-bottom: 1rem;
  }
  #about-page-title::after {
    border-bottom: 3px solid var(--textColor);
  }
  #about-page-left {
    width: 90%;
  }
  #about-page-left-text {
    width: 100%;
    margin: auto;
  }
  #about-page-right {
    width: 100%;
  }
  #about-page-right-text {
    width: 90%;
  }
  #about-page p {
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 500px) {
  .logo-github {
    font-size: 15px;
  }
  .logo-linkedin-about {
    font-size: 15px;
  }
  .logo-cv-about {
    font-size: 15px;
  }
}

/* ------------------------------------ Projet Fil Rouge 1 ----------------------------------- */

#pfr-one-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  background-color: var(--backgroundColor);
  padding: 0rem 5%;
}

#projects-page-title {
  margin: 3rem auto 0rem auto;
  padding-bottom: 2rem;
  position: relative;
  font-weight: bold;
  font-family: "Russo_one";
  font-size: 45px;
}

#projects-page-title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60%;
  border-bottom: 5px solid var(--textColor);
}

#pfr-one-page-title {
  color: var(--highlightColor);
  margin-top: 2rem;
  font-size: 40px;
}

#pfr-one-page-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}

#pfr-one-page-left {
  width: 50%;
}

#pfr-one-page-text {
  width: 70%;
  text-align: justify;
  margin: auto;
}

#pfr-one-page-text p:nth-of-type(1) {
  margin-bottom: 2rem;
}

#pfr-one-page-right {
  height: 450px;
  width: 700px;
  border: 7px solid black;
}

@media only screen and (max-width: 1587px) {
  #pfr-one-page-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #pfr-one-page-left {
    width: 90%;
    margin-bottom: 2rem;
  }
  #pfr-one-page-right {
    height: 35vh;
    max-width: 600px;
    width: 60vh;
  }
  #pfr-one-page-text {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 800px) {
  #projects-page-title {
    margin: 1rem auto 0rem auto;
    font-size: 30px;
    padding-bottom: 1rem;
  }
  #projects-page-title::after {
    border-bottom: 3px solid var(--textColor);
  }
  #pfr-one-page-title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 600px) {
  /* #pfr-one-page-content {
    justify-content: start;
  } */
  #pfr-one-page-right {
    width: 95%;
    height: 55vw;
  }
}

/* ------------------------------------ Projet Fil Rouge 2 ----------------------------------- */

#pfr-two-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  background-color: var(--backgroundColor);
  padding: 0rem 5%;
}

#pfr-two-page-title {
  color: var(--highlightColor);
  margin-top: 5rem;
  font-size: 40px;
}

#pfr-two-page-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}

#pfr-two-page-right {
  width: 50%;
}

#pfr-two-page-text {
  width: 70%;
  text-align: justify;
  margin: auto;
}

#pfr-two-page-text p:nth-of-type(1) {
  margin-bottom: 2rem;
}

#pfr-two-page-left {
  height: 450px;
  width: 700px;
  border: 7px solid black;
}

@media only screen and (max-width: 1587px) {
  #pfr-two-page-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #pfr-two-page-right {
    min-height: auto;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  #pfr-two-page-text {
    width: 100%;
  }
  #pfr-two-page-left {
    height: 350px;
    max-width: 600px;
    width: 95%;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 800px) {
  #pfr-two-page-title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 600px) {
  #pfr-two-page-content {
    justify-content: start;
  }
  #pfr-two-page-left {
    width: 95%;
    height: 55vw;
  }
}

/* ------------------------------------ Projet Fil Rouge 3 ----------------------------------- */

#pfr-three-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  background-color: var(--backgroundColor);
  padding: 0rem 5%;
}

#pfr-three-page-title {
  color: var(--highlightColor);
  margin-top: 5rem;
  font-size: 40px;
}

#pfr-three-page-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}

#pfr-three-page-left {
  width: 50%;
}

#pfr-three-page-text {
  width: 70%;
  text-align: justify;
  margin: auto;
}

#pfr-three-page-text p:nth-of-type(1) {
  margin-bottom: 2rem;
}

#pfr-three-page-right {
  height: 450px;
  width: 700px;
  border: 7px solid black;
}

@media only screen and (max-width: 1587px) {
  #pfr-three-page-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #pfr-three-page-left {
    min-height: auto;
    width: 90%;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
  #pfr-three-page-text {
    width: 100%;
  }
  #pfr-three-page-right {
    height: 350px;
    max-width: 600px;
    width: 95%;
  }
}

@media only screen and (max-width: 800px) {
  #pfr-three-page-title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 600px) {
  #pfr-three-page-content {
    justify-content: start;
  }
  #pfr-three-page-right {
    width: 95%;
    height: 55vw;
  }
}

/* ------------------------------------ Projet Fil Rouge 4 ----------------------------------- */

#pfr-four-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  background-color: var(--backgroundColor);
  padding: 0rem 5%;
}

#pfr-four-page-title {
  color: var(--highlightColor);
  margin-top: 5rem;
  font-size: 40px;
}

#pfr-four-page-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}

#pfr-four-page-right {
  width: 50%;
}

#pfr-four-page-text {
  width: 70%;
  text-align: justify;
  margin: auto;
}

#pfr-four-page-text p:nth-of-type(1) {
  margin-bottom: 2rem;
}

#pfr-four-page-left {
  height: 450px;
  width: 300px;
  border-radius: 15%;
}

@media only screen and (max-width: 1587px) {
  #pfr-four-page-title {
    margin-top: 1rem;
  }
  #pfr-four-page-right {
    min-height: auto;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  #pfr-four-page-text {
    width: 100%;
  }
  #pfr-four-page-left {
    height: 450px;
    width: 300px;
    border-radius: 15%;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 800px) {
  #pfr-four-page-title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 600px) {
  #pfr-four-page-content {
    justify-content: center;
  }
  #pfr-four-page-left {
    width: 60%;
    height: 55vw;
  }
}

/* ------------------------------------ Internship ----------------------------------- */

#internship-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  background-color: var(--backgroundColor);
  padding: 0rem 5%;
}

#internship-page-title {
  color: var(--highlightColor);
  margin-top: 3rem;
  font-size: 40px;
}

#internship-page-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: auto;
}

#internship-page-left, #internship-page-right {
  width: 50%;
}

#internship-page-left-text, #internship-page-right-text {
  width: 70%;
  text-align: justify;
  margin: auto;
}

#internship-page-text p:nth-of-type(1) {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 1325px) {
  #internship-page-title {
    height: auto;
    margin-top: 1rem;
  }
  #internship-page-left, #internship-page-right {
    width: 100%;
    min-height: auto;
  }
  #internship-page-left-text, #internship-page-right-text {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  #internship-page-title {
    font-size: 25px;
  }
}

/* ------------------------------------ Contact ----------------------------------- */

#contact-page {
  min-height: 100%;
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 2rem;
  position: relative;
}

#contact-page-background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 90px;
  opacity: 0.04;
  font-weight: bolder;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#contact-page-background-list {
  list-style: none;
}

#contact-page-title {
  margin: 3rem auto 0rem auto;
  padding-bottom: 3rem;
  position: relative;
  font-weight: bold;
  font-family: "Russo_one";
  font-size: 45px;
  height: 5%;
}

#contact-page-title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60%;
  border-bottom: 5px solid var(--textColor);
}

#contact-page-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 95%;
  justify-content: center;
  text-align: center;
}

.logo-email {
  font-size: 30px;
}

.logo-linkedin {
  font-size: 30px;
  padding: 2px;
  color: var(--backgroundColor);
  background-color: var(--textColor);
}

#contact-page a {
  color: inherit;
  text-decoration: none;
}

#contact-page-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: opacityOn 3s infinite 0s normal forwards;
  cursor: pointer;
  font-size: 30px;
}

#contact-page-up div {
  font-size: 15px;
}

@media only screen and (max-width: 800px) {
  #contact-page-title {
    margin: 1rem auto 0rem auto;
    font-size: 30px;
    padding-bottom: 1rem;
  }
  #contact-page-title::after {
    border-bottom: 3px solid var(--textColor);
  }
  #contact-page-background {
    font-size: 70px;
  }
}

@media only screen and (max-width: 500px) {
  #contact-page-background {
    font-size: 50px;
  }
}